:root {
  --background-linear-gradient-2: #6fc6c0;
  --background-linear-gradient-3: #0a7c76;
  --background-linear-gradient-4: #042827;

  --color-purple-1: #1d0841;
  --color-purple-2: #9737bb;
  --color-purple-3: #d8abff;
  --color-purple-4: #e8d2ff;

  --color-active-3: #6fc6c0;
  --color-active-2: #0a7c76;
  --color-active-1: #042827;
  --color-active-4: #d0d8c4;

  --color-red-incorrect: #ff3311;

  --color-beige-1: #7c4f19;
  --color-beige-2: #c09e55;
  --color-beige-3: #ffedca;

  --color-green--live: #acff76;

  --color-gray--live: #4b4b4b;

  --color-black-1: #000;
  --color-black-2: #1c1c1c;
  --color-black-3: #262626;
  --color-black-4: #3a3a3a;

  --text-primary: white;
}
